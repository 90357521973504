.credit-card-optimizer {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px;
  font-family: 'Roboto', Arial, sans-serif;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

h1, h2 {
  color: #2c3e50;
  margin-bottom: 20px;
  text-align: center;
}

h1 {
  font-size: 2.5em;
  margin-bottom: 30px;
}

h2 {
  font-size: 1.8em;
  margin-top: 40px;
}

form {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

input[type="text"],
input[type="number"],
input[type="date"],
button {
  width: 100%;
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s, box-shadow 0.3s;
}

input[type="text"]:focus,
input[type="number"]:focus,
input[type="date"]:focus {
  border-color: #3498db;
  box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
  outline: none;
}

button {
  background-color: #3498db;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

button:hover {
  background-color: #2980b9;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  background-color: white;
  border: 1px solid #e0e0e0;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s;
}

li:hover {
  transform: translateY(-2px);
}

.checkbox-group {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.checkbox-group div {
  flex-basis: calc(50% - 7.5px);
  display: flex;
  align-items: center;
}

.checkbox-group input[type="checkbox"] {
  margin-right: 10px;
}

.best-card {
  background-color: #e8f4fd;
  border: 2px solid #3498db;
  padding: 20px;
  border-radius: 8px;
  margin-top: 30px;
}

.best-card h3 {
  color: #2c3e50;
  margin-top: 0;
}

.tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.tabs button {
  background-color: #ecf0f1;
  color: #34495e;
  border: none;
  padding: 12px 20px;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  border-radius: 4px;
}

.tabs button.active {
  background-color: #3498db;
  color: white;
}

.tab-content {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Responsive design */
@media (max-width: 600px) {
  .credit-card-optimizer {
    padding: 20px;
  }
  
  .tabs button {
    padding: 10px;
    font-size: 14px;
  }
  
  .checkbox-group div {
    flex-basis: 100%;
  }
}

.category-selector {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin-bottom: 30px;
}

.category-button {
  background-color: #ffffff;
  border: 2px solid #d0d0d0;
  border-radius: 8px;
  padding: 12px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  flex-grow: 1;
  min-width: 120px;
  max-width: calc(50% - 5px);
  color: #333333;
  font-weight: 500;
}

.category-button:hover {
  background-color: #f0f0f0;
  border-color: #b0b0b0;
}

.category-button.selected {
  background-color: #3498db;
  color: white;
  border-color: #2980b9;
}

@media (max-width: 600px) {
  .category-button {
    max-width: 100%;
  }
}